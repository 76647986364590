import { useState, useEffect } from 'react';
import { checkEmptyInput, checkNumberInput } from '../../../utils/helper/inputHelper';
import { searchUsers } from '../../../utils/api/userFetchs';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { createLicenseKey } from '../../../utils/api/licensekeyFetchs';
import { useUser } from '../../../utils/contexts/userContext';
import { getLicenseKey } from '../../../utils/api/licensekeyFetchs';
import { getLicenseKeysFromClientId } from "../../../utils/api/userFetchs";
import Loader from "react-js-loader";
import { Link } from 'react-router-dom'

export default function AccountAdminPanel() {
    const { user } = useUser()

    const [copied, setCopied] = useState(false)
    const [client, setClient] = useState(null)
    const [clientLicenseKeys, setClientLicenseKeys] = useState(null)

    useEffect(() => {
        getLicenseKeysFromClientId(user , client ? client : user).then(license_keys => setClientLicenseKeys(license_keys));
    }, [client ? client._id : user._id])

    const [retrievedClients, setretrievedClients] = useState([])
    const [name, setName] = useState("")

    const [messageValidateKey, setMessageValidateKey] = useState("")
    const [errorMessageConfiguration, setErrorMessageConfiguration] = useState("")
    const [keyData, setKeyData] = useState({
        user: "",
        partition_name: "",
        os_version: "",
        model_number: "",
        serie_number: "",
        max_user_count: 1,
        end_date: new Date(),
        is_available: true
    })

    const options = {
        title: 'Confirmez la création de la clé de license pour le client.',
        message: "En cliquant sur confirmer, vous acceptez la création d'une clé de licence.",
        buttons: [
            {
                label: 'Confirmer',
                onClick: () => handleKeyCreation()
            },
            {
                label: 'Revenir en lieu sûr',
                onClick: () => { }
            }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
        willUnmount: () => { },
        afterClose: () => { },
        onClickOutside: () => { },
        onKeypress: () => { },
        onKeypressEscape: () => { },
        overlayClassName: "overlay-custom-class-name"
    };

    useEffect(() => {
        const search = setTimeout(() => {
            if (name !== "") {
                searchUsers(name).then((users) => setretrievedClients(users))
            }
        }, 500);

        return () => clearTimeout(search);
    }, [name]);

    useEffect(() => {
        const elems = document.querySelectorAll(".section-account-user-list-item")

        elems.forEach(function (elem) {
            elem.addEventListener("click", function () {
                document.querySelector(".selected-account-user-list-item")?.classList.remove('selected-account-user-list-item')
                elem.classList.add("selected-account-user-list-item")
            });
        });
    }, [retrievedClients])

    function handleEndDateChange(months) {
        if(isNaN(parseInt(months))) {
            setErrorMessageConfiguration("Le nombre de mois doit être un nombre.")
            return
        }
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const currentDay = currentDate.getDate();
        
        // Calculate the new month and year
        const newMonth = currentMonth + parseInt(months);
        const newYear = currentYear + Math.floor(newMonth / 12);
        
        // The month in Date constructor is 0-based, so we use modulo to get correct month
        const finalMonth = newMonth % 12;
        
        // Create the new date
        const newDate = new Date(newYear, finalMonth, currentDay);
        
        // Adjust if the day does not exist in the new month
        if (newDate.getMonth() !== finalMonth) {
            newDate.setDate(0);
        }
        setKeyData((previous) => ({ ...previous, "end_date": newDate }))
    }

    function handleKeyCreation() {
        // Contrôle des données
        if (keyData.partition_name === "" || keyData.os_version === "" || keyData.model_number === "" ||
            keyData.serie_number === "" || keyData.max_user_count === "" || keyData.end_date === "") {
            setErrorMessageConfiguration("Les champs ne peuvent pas être vide.")
            return
        }

        if (keyData.partition_name.length > 8) {
            setErrorMessageConfiguration("Le nom de la partition doit être inférieur à 8 caractères.")
            return
        }

        keyData.user = client._id

        createLicenseKey(keyData, client._id).then((key) => {
            if (key) {
                setMessageValidateKey("La clé de licence a été créée avec succès.")
            } else {
                setMessageValidateKey("Une erreur est survenue lors de la création de la clé de licence.")
            }
        })
    }

    function copyToClipboard() {
        navigator.clipboard.writeText(client._id)
        setCopied(true)
    }

    if (!user.is_admin) {
        window.history.back()
    } else {


        return (
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <section className="section-content-container">
                            <header>
                                <h1 className="section-content-title">Panneau d'administration</h1>
                                <h2 className="section-content-subtitle">Gestion des clients et leurs clés de licence</h2>
                            </header>
                        </section>

                        <section className="section-content-container">
                            <h1 className="section-subtitle-special">Selection d'un client</h1>
                            <p>
                                Il faut choisir un client pour lequel créer une clé de licence. Le client doit avoir un compte sur le site dips400.com.
                                Dans le cas contraire, lui <a href="/register">créer un compte</a>.
                            </p>

                            <div className="section-columns-layout" grid-template={client ? "1,3" : ""}>
                                <div>
                                    <input placeholder="Rechercher par le nom du client" onChange={(e) => setName(e.target.value)} />

                                    <ul>
                                        {
                                            retrievedClients.map((client, index) => (
                                                <li key={client + index} className='section-account-user-list-item' onClick={() => setClient(client)}>
                                                    <span>{client.firstname + ' ' + client.lastname}</span>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>

                                {client &&
                                    <div className="section-info-container section-row-layout">
                                        <div className="section-columns-layout" grid-template="1,1">
                                            <div className="section-account-personal-infos-container">
                                                <span><strong>Nom</strong>{client.lastname}</span>
                                                <span><strong>Prénom</strong>{client.firstname}</span>

                                            </div>

                                            <div className="section-account-personal-infos-container">
                                                <span><strong>Mail</strong>{client.mail}</span>
                                                <span><strong>Téléphone</strong>{client.phone}</span>
                                            </div>
                                        </div>

                                        <div className="section-account-personal-infos-container">
                                            <strong>ID Client</strong>
                                            <span className="license-key-container" onClick={() => copyToClipboard()}>{client._id}</span>
                                            <span className="license-key-copy-container">{copied ? "Copié" : "Cliquez pour copier"}</span>
                                        </div>

                                        <div className="section-account-personal-infos-container">
                                            <strong>Clé(s) de licence</strong>
                                            <ul className="section-account-licensekey-list">
                                    {!clientLicenseKeys ?
                                        (<Loader type="rectangular-ping" color="#071F5A" bgColor="#071F5A" size={200} title="C'est bien vide ici..." />) :
                                        (<>
                                            {clientLicenseKeys.map(key =>
                                            (<Link key={key._id} to={`/account/subscriptions/${key._id}`}>
                                                <li className="section-account-user-licensekey-item">
                                                    <span>{key.partition_name}</span>
                                                    <span>{key.key.substring(0, 12)}..</span>
                                                </li>
                                            </Link>))
                                            }
                                        </>)
                                    }
                                </ul>
                                        </div>
                                    </div>
                                }
                            </div>
                        </section>

                        <section className="section-content-container">
                            <h1 className="section-subtitle-special">Création de clé de licence</h1>
                            <p>
                                Pour créer une clé de license au client séléctionné ci-dessus, veuillez remplir tous les champs ci-desssous :
                            </p>

                            <form onSubmit={(e) => e.preventDefault()} className="section-row-layout">
                                <div className="section-columns-layout" grid-template="1,1,1">
                                    <div>
                                        <label>Nom de la partition*</label>
                                        <input type="text"
                                            onChange={(e) => setKeyData((previous) => ({ ...previous, "partition_name": e.target.value }))}
                                            onBlur={(e) => setErrorMessageConfiguration(checkEmptyInput(e.target.value))} />
                                        <label>Version de l'OS*</label>
                                        <input type="text"
                                            onChange={(e) => setKeyData((previous) => ({ ...previous, "os_version": e.target.value }))}
                                            onBlur={(e) => setErrorMessageConfiguration(checkEmptyInput(e.target.value))} />
                                    </div>

                                    <div>
                                        <label>Numéro de modèle*</label>
                                        <input
                                            type="text"
                                            onChange={(e) => setKeyData((previous) => ({ ...previous, "model_number": e.target.value }))}
                                            onBlur={(e) => setErrorMessageConfiguration(checkEmptyInput(e.target.value))} />
                                        <label>Numéro de série*</label>
                                        <input
                                            type="text"
                                            onChange={(e) => setKeyData((previous) => ({ ...previous, "serie_number": e.target.value }))}
                                            onBlur={(e) => setErrorMessageConfiguration(checkEmptyInput(e.target.value))} />
                                    </div>

                                    <div>
                                        <label>Nombre de connexion(s)</label>
                                        <input
                                            type="text"
                                            onChange={(e) => setKeyData((previous) => ({ ...previous, "max_user_count": parseInt(e.target.value) }))}
                                            onBlur={(e) => setErrorMessageConfiguration(checkEmptyInput(e.target.value) || checkNumberInput(e.target.value))} />

                                        <label>Engagement (mois)</label>
                                        <input
                                            type="text"
                                            onChange={(e) => handleEndDateChange(e.target.value)}
                                            onBlur={(e) => setErrorMessageConfiguration(checkEmptyInput(e.target.value) || checkNumberInput(e.target.value))} />
                                    </div>
                                </div>

                                <span className="error-text">{errorMessageConfiguration}</span>

                                <button
                                    className="dark-bg"
                                    onClick={() => client ? confirmAlert(options) : setErrorMessageConfiguration("Vous devez selectionner un client valide ci-dessus.")}>Valider</button>
                            </form>

                            <h3>{messageValidateKey}</h3>
                        </section>
                    </div>
                </div>
            </section>
        )
    }
}