import dayjs from "dayjs";
import Loader from "react-js-loader";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { useUser } from "../../../utils/contexts/userContext";
import { getLicenseKey } from "../../../utils/api/licensekeyFetchs";
import { getBillingInformations, updateCustomer } from "../../../utils/api/payementFetchs";
import { checkEmptyInput } from "../../../utils/helper/inputHelper";
import LicensekeyDetails from "../Components/LicenseKeyDetails";
import PricingPlanModification from "../Components/PricingPlanModification";
import Payement from "../../Payement/Payement";

export default function AccountSubscription() {

    const { licenseKeyId } = useParams();
    const { user } = useUser();

    const [licenseKey, setLicensekey] = useState(null)

    const [customerInfos, setCustomerInfos] = useState(null)
    const [subscriptionInfos, setSubscriptionInfos] = useState(null)
    const [paymentMethodInfos, setPaymentMethodInfos] = useState(null)

    const [errorMessage, setErrorMessage] = useState("")
    const [hasBeenUpdated, setHasBeenUpdated] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (licenseKeyId && user._id) {
            getLicenseKey(licenseKeyId).then(key => {
                setLicensekey(key)

                if (user.customer_id && key.subscription) {
                    getBillingInformations(user.customer_id, key.subscription.id).then(data => {
                        setSubscriptionInfos(data.subscription)
                        setCustomerInfos(data.customer)
                        setPaymentMethodInfos(data.paymentMethod)
                    }).catch(e => { })
                }
            })
        }
    }, [licenseKeyId, user])

    function updateCustomerData(e) {
        e.preventDefault()

        if (!customerInfos.shipping.name || !customerInfos.email || !customerInfos.shipping.address.city
            || !customerInfos.shipping.address.country || !customerInfos.shipping.address.line1 || !customerInfos.shipping.address.postal_code) {
            setErrorMessage('Les champs * doivent être renseignés')
            return;
        }
        setIsLoading(true)
        updateCustomer(user, customerInfos).then(({ message }) => {
            if (message) {
                setHasBeenUpdated(true)
                setIsLoading(false)
            } else {
                setHasBeenUpdated(false)
            }
        })
    }

    if (!licenseKey ) {
        return (
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-content-container">
                            <div className="section-vertical-centered-layout">
                                <Loader type="bubble-ping" color="#071F5A" bgColor="#071F5A" size={200} />
                                <h2>Nous chargons activement vos données. Si cela prend trop de temps, veuillez réessayer plus tard ou nous contacter...</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    } else {
        return (
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-content-container">
                            <header>
                                <h1 className="section-content-title">Gérer votre abonnement</h1>
                                <h2 className="section-content-subtitle">Et retrouvez toutes les informations nécessaires</h2>
                            </header>

                            <LicensekeyDetails licenseKey={licenseKey} />

                            {customerInfos !== null && subscriptionInfos !== null && paymentMethodInfos !== null && (
                                <>
                                    <h2 className="section-subtitle-special">Détails de l'abonnement</h2>
                                    <div className="section-info-container">
                                        <ul className="section-account-subscription-infos-list">
                                            <li className="section-account-subscription-info">
                                                <strong>Date de début</strong> {dayjs(subscriptionInfos.created).format("MM-DD-YYYY")}
                                            </li>
                                            <li className="section-account-subscription-info">
                                                <strong>Status</strong> {subscriptionInfos.status}
                                            </li>
                                            <li className="section-account-subscription-info">

                                                {paymentMethodInfos.card && <span><strong>Méthode de payement</strong> {paymentMethodInfos.card.brand} | **** **** **** {paymentMethodInfos.card.last4}</span>}
                                                {paymentMethodInfos.sepa_debit && <span><strong>Méthode de payement</strong> {paymentMethodInfos.sepa_debit.bank_name} | **** **** **** {paymentMethodInfos.sepa_debit.last4}</span>}
                                                {paymentMethodInfos.bacs_debit && <span><strong>Méthode de payement</strong> {paymentMethodInfos.bacs_debit.bank_name} | **** **** **** {paymentMethodInfos.bacs_debit.last4}</span>}
                                                {paymentMethodInfos.paypal && <span><strong>Méthode de payement</strong> {paymentMethodInfos.paypal.payer_email}</span>}
                                            </li>
                                        </ul>
                                    </div>
                                    <a href="https://billing.stripe.com/p/login/test_9AQ4jNes77QL1na9AA">
                                        <button className="dark-bg" style={{ maxWidth: '350px' }}>Supprimer l'abonnement et/ou les informations de paiement</button>
                                    </a>

                                    <h2 className="section-subtitle-special">Détails de facturation</h2>
                                    <div className="section-info-container">
                                        <div className="section-columns-layout" grid-template="1,1,1">
                                            <div>
                                                <label>Entreprise</label>
                                                <input
                                                    type="text"
                                                    onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))}
                                                    onChange={(e) => {
                                                        setCustomerInfos((previous) =>
                                                            ({ ...previous, 'name': e.target.value }))
                                                        setCustomerInfos((previous) =>
                                                            ({ ...previous, "shipping": { ...previous.shipping, 'name': e.target.value } }))
                                                    }}
                                                    defaultValue={customerInfos.shipping.name} />
                                                <label>E-mail</label>
                                                <input
                                                    type="text"
                                                    onChange={(e) => setCustomerInfos((previous) => ({ ...previous, 'email': e.target.value }))}
                                                    onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))}
                                                    defaultValue={customerInfos.email} />
                                            </div>
                                            <div>
                                                <div className="section-columns-layout" grid-template="3,1" style={{ gap: '1em' }}>
                                                    <div>
                                                        <label>Ville</label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => {
                                                                setCustomerInfos((previous) =>
                                                                    ({ ...previous, "address": { ...previous.address, 'city': e.target.value } }))
                                                                setCustomerInfos((previous) =>
                                                                ({
                                                                    ...previous, "shipping":
                                                                        { ...previous.shipping, "address": { ...previous.address, 'city': e.target.value } }
                                                                }))
                                                            }}
                                                            onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))}
                                                            defaultValue={customerInfos.shipping.address.city} />
                                                    </div>
                                                    <div>
                                                        <label>Pays</label>
                                                        <input
                                                            type="text"
                                                            onChange={(e) => {
                                                                setCustomerInfos((previous) =>
                                                                    ({ ...previous, "address": { ...previous.address, 'country': e.target.value } }))
                                                                setCustomerInfos((previous) =>
                                                                ({
                                                                    ...previous, "shipping":
                                                                        { ...previous.shipping, "address": { ...previous.address, 'country': e.target.value } }
                                                                }))
                                                            }}
                                                            onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))}
                                                            defaultValue={customerInfos.shipping.address.country} />
                                                    </div>
                                                </div>
                                                <label>Adresse</label>
                                                <input
                                                    type="text"
                                                    onChange={(e) => {
                                                        setCustomerInfos((previous) =>
                                                            ({ ...previous, "address": { ...previous.address, 'line1': e.target.value } }))
                                                        setCustomerInfos((previous) =>
                                                        ({
                                                            ...previous, "shipping":
                                                                { ...previous.shipping, "address": { ...previous.address, 'line1': e.target.value } }
                                                        }))
                                                    }}
                                                    onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))}
                                                    defaultValue={customerInfos.shipping.address.line1} />
                                            </div>
                                            <div>
                                                <label>Code Postal</label>
                                                <input
                                                    type="text"
                                                    onChange={(e) => {
                                                        setCustomerInfos((previous) =>
                                                            ({ ...previous, "address": { ...previous.address, 'postal_code': e.target.value } }))
                                                        setCustomerInfos((previous) =>
                                                        ({
                                                            ...previous, "shipping":
                                                                { ...previous.shipping, "address": { ...previous.address, 'postal_code': e.target.value } }
                                                        }))
                                                    }}
                                                    onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))}
                                                    defaultValue={customerInfos.shipping.address.postal_code} />
                                            </div>
                                        </div>

                                        {hasBeenUpdated && <span>Vos modifications ont été correctement sauvegardées !</span>}
                                        <span>{errorMessage}</span>
                                    </div>
                                    <button className={"dark-bg " + (isLoading ? "loading-button" : undefined)} disabled={isLoading} onClick={updateCustomerData}>Modifier</button>
                                </>)}
                            {/*<PricingPlanModification licenseKey={licenseKey} />*/}
                        </div>
                    </div>
                </div>
            </section >
        )
    }
}