export const installCommands = {
    csharp : {
        data: '$ dotnet add package Peasys'
    },
    python: {
        data: '$ pip install Peasys'
    },
    ruby: {
        data: 'gem install peasys'
    },
    java: {
        data: 'implementation "com.dips:peasys-java:1.0.0"'
    }
}