import updateHead from "../../../utils/helper/updatePageHead";
import CodeBlockWrapper from "../../Utils/CodeBlockWrapper";
import { installCommands } from "../../../data/Documentation/InstallData";
import { Link } from "react-router-dom";

export default function DocInstallation() {
    const title = "DIPS | Documentation Peasys";
    const cssPath = "/style/documentation.css";
    const api_url = process.env.NODE_ENV === "production" ? "https://dips400.com" : "http://localhost:8080"

    return (
        <>
            {updateHead({ title, cssPath })}
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <section className="section-content-container">
                            <header>
                                <h1 className="section-content-title">Lancez-vous dans l'installation de Peasys</h1>
                                <h2 className="section-content-subtitle">Et commencez à développer aussi tôt</h2>
                            </header>
                            <p>
                                Le service Peasys étant une API entre vos serveurs IBMi et vos postes clients, il est donc nécéssaire d'installer Peasys aux deux
                                extrémités de votre système. Suivez pas à pas le processus afin de mieux comprendre son intégration dans votre SI.
                            </p>
                        </section>
        
                        <section className="section-content-container" id="peasys-administrator-install">
                            <h1 className="section-subtitle-special">Installation côté serveur</h1>
                            <p>
                                <strong>PeasysAdministrator</strong> est une application développée par DIPS qui permet toute l'administration de la partie serveur du service Peasys.
                                Elle permet notamment une installation paramétrée du service, la création de plusieurs environnements de travail et la mise à jour de Peasys.
                            </p>
                            <a href={api_url+"/api/download/peasys-administrator"}>Installer Peasys Administrator</a>
                            <p>
                                Téléchargez l'installateur de PeasysAdministrator en cliquant sur le lien ci-dessus puis lancez l'application et suivez les instructions pour l'installation.
                            </p>
                        </section>

                        <section className="section-content-container">
                            <h1 className="section-subtitle-special">Installation côté client</h1>
                            <p>
                                La partie client prend la forme d'une librairie disponible dans une multitude de languages de programmation actuels qu'il suffit
                                d'ajouter commme dépendance à vos logiciels pour pouvoir l'intégrer à vos développement. N'hésitez pas à
                                nous <Link to='/contact' style={{ display: 'unset' }}>soumettre vos besoins</Link> pour orienter la réplication de la libraire
                                dans vos outils de travail.
                            </p>
                            <footer >
                                <h3><strong>Installation via ligne de commande : </strong></h3>
                                <CodeBlockWrapper
                                    data={installCommands}
                                    showLineNumbers={false} />
                            </footer>
                            <footer >
                                <h3 style={{ display: 'flex' }}><strong>Installation via sources : </strong>
                                    <a href="https://github.com/dips400">
                                        <img src="/assets/github.svg" className="icon" style={{ width: '35px', height: '35px' }} />
                                        Github
                                    </a>
                                </h3>
                                <p>Visitez notre page github pour suivre les différents tutoriels d'installation de la librairie côté client.</p>
                                <div style={{ display: "flex", height: 'min(40px,10vw)' }}>
                                <a href="https://github.com/dips400/peasys-dotnet" title='C-Sharp'><img src="/assets/csharp.svg" alt="csharp" className="icon" style={{ height: '35px' }} /></a>
                                    <a href="https://github.com/dips400/peasys-python" title='Python'><img src="/assets/python_black.svg" alt="python" className="icon" style={{ height: '35px' }} /></a>
                                    <a href="https://github.com/dips400/peasys-ruby" title='Ruby'><img src="/assets/ruby80.svg" alt="java" className="icon" style={{ height: '35px' }} /></a>
                                    <a href="https://github.com/dips400/peasys-java" title='Java'><img src="/assets/java_black.svg" alt="java" className="icon" style={{ height: '35px' }} /></a>
                                </div>

                            </footer>
                        </section>
                    </div>
                </div>
            </section>
        </>
    )
}