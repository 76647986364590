export const connexionOnlineCode = {
    csharp: {
        data: `using Peasys;

namespace ConnexionPeasys
{
    class Main
        {
        static void Main(string[] args)
        {
            // connexion
            PeaClient conn = new PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", onlineVersion: true, retreiveStatistics: false);
            Console.WriteLine("Status de connexion : " + conn.ConnectionMessage);

            // execute queries

            // deconnexion
            conn.Disconnect();
        }
    }
}`
    },
    python: {
        data: `from peasys import pea_client

conn = pea_client.PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", onlineVersion=True, retreiveStatistics=False)
print("Status de connexion : " + conn.connexion_message)

// execute queries

conn.disconnect()`
    },
    ruby: {
        data: `require "peasys"

conn = PeaClient.new("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false)
puts conn.connexion_message

// execute queries

conn.disconnect`
    },
    java: {
        data: `import Peasys.PeaClient;
import Peasys.PeaResponse.*;
        
public class Main {
    public static void main(String[] args) {
        try {
            PeaClient conn = new PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
            System.out.println(conn.connectionStatus);
            System.out.println(conn.connexionMessage);
            
            // execute queries
        
            conn.disconnect();
        } catch (Exception e) {
            System.out.println(e.getMessage());
        }
    }
}`
    }
}

export const connexionOfflineCode = {
    csharp: {
        data: `using Peasys;

namespace ConnexionPeasys
{
    class Main
        {
        static void Main(string[] args)
        {
            // connexion
            PeaClient conn = new PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", onlineVersion: false, retreiveStatistics: false);
            Console.WriteLine("Status de connexion : " + conn.ConnectionMessage);

            // execute queries

            // deconnexion
            conn.Disconnect();
        }
    }
}`
    },
    python: {
        data: `from peasys import pea_client

conn = pea_client.PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", onlineVersion=False, retreiveStatistics=False)
print("Status de connexion : " + conn.connexion_message)

// execute queries

conn.disconnect()`
    },
    ruby: {
        data: `require "peasys"

conn = PeaClient.new("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", false, false)
puts conn.connexion_message

// execute queries

conn.disconnect`
    },
    java: {
        data: `import Peasys.PeaClient;
import Peasys.PeaResponse.*;
        
public class Main {
    public static void main(String[] args) {
        try {
            PeaClient conn = new PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", false, false);
            System.out.println(conn.connectionStatus);
            System.out.println(conn.connexionMessage);
            
            // execute queries
        
            conn.disconnect();
        } catch (Exception e) {
            System.out.println(e.getMessage());
        }
    }
}`
    }
}