import updateHead from "../../utils/helper/updatePageHead";
import PhoneInput from "react-phone-input-2"
import 'react-phone-input-2/lib/style.css'
import bcrypt from "bcryptjs-react"
import emailjs from '@emailjs/browser'
import KeyPointsDetails from './tmpl/KeyPointsDetails'
import { Link } from "react-router-dom";
import { useUser } from "../../utils/contexts/userContext";
import { checkEmptyInput, checkMailInput, disableButton } from "../../utils/helper/inputHelper";
import { checkEmailAvailability, checkLogin, createUser } from "../../utils/api/userFetchs";
import { useState, useEffect } from "react";

export default function Register() {
    const title = "DIPS | Inscrivez-vous !";
    const cssPath = "/style/register.css";

    const { loginLocalUser } = useUser();

    const [isUserCreated, setIsUserCreated] = useState(false)
    const [errorMessage, setErrorMessage] = useState("");
    const [errorMailMessage, setErrorMailMessage] = useState("");
    const [isEmailAvailable, setIsEmailAvailable] = useState(true)
    const [isInputsFilled, setIsInputsFilled] = useState(true)
    const [userData, setUser] = useState({
        lastname: "",
        firstname: "",
        mail: "",
        phone: "",
        license_keys: [],
        creation_date: "",
        company: "",
        password: ""
    })

    useEffect(() => {
        const debounceCheckEmail = setTimeout(() => {
            if(userData.mail){
                checkEmailAvailability(userData.mail).then(({code}) => {
                    if(code !== 200){
                        setErrorMailMessage("(e-mail déjà utilisé)");
                        setIsEmailAvailable(false)
                    } else {
                        setIsEmailAvailable(true)
                    }
                })
            }
        }, 500);

        return () => clearTimeout(debounceCheckEmail);
    }, [userData.mail]);

    useEffect(() => {
        if (!userData.lastname || !userData.firstname || !userData.mail || !userData.password || !userData.phone) {
            setErrorMessage("Les champs * doivent être renseignés")
            setIsInputsFilled(false)
            return;
        }
        else {
            setIsInputsFilled(true)
        }
    })
    
    const registerUser = async (e) => {
        e.preventDefault();
        
        if (isEmailAvailable && isInputsFilled) {
            userData.creation_date = Date.now();

        let decryptedPassword = userData.password

        const salt = await bcrypt.genSalt(userData.password.size);
        userData.password = await bcrypt.hash(userData.password, salt)

        const createdUser = await createUser(userData);
        const { user, token } = await checkLogin({ mail: createdUser.mail, password: decryptedPassword })

        loginLocalUser(user, token)
        emailjs.send("service_nnt9ye1", "template_olhfgbi", { lastname: user.lastname, firstname: user.firstname, email: user.mail }, "KbgKh-EcBL5hy_lPB").then(data => {
            console.log(data.status)
        });

        setIsUserCreated(true)
        }
        else if (!isInputsFilled) {
            setErrorMessage("Les champs * doivent être renseignés")
            return;
        }
        else if (!isEmailAvailable) {
            setErrorMailMessage("(e-mail déjà utilisé)");
            return;
        }
        
    }

    return (
        <>
            {updateHead({ title, cssPaths: [cssPath] })}
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-register-background"></div>
                        <div className="section-columns-layout" grid-template="1.2,1.2,0.6">
                            <KeyPointsDetails />
                            <div className="section-row-layout section-info-container">
                                <img src="/assets/logo_dips.svg" alt="company logo" className="icon logo-full"/>

                                {isUserCreated ? (
                                    <div>
                                        <h1>Votre compte a été créé avec succès ! </h1>
                                        <Link to='/account'>
                                            <button className="dark-bg">
                                                visiter votre espace
                                            </button>
                                        </Link>
                                    </div>
                                ) : (
                                    <>
                                        <form onSubmit={registerUser}>
                                            <div className="section-columns-layout" grid-template="1,1">
                                                <div>
                                                    <label>Nom*</label>
                                                    <input
                                                        type="text"
                                                        name="lastname"
                                                        onChange={(e) => setUser((previous) => ({ ...previous, "lastname": e.target.value }))}
                                                        onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))} />
                                                </div>
                                                <div>
                                                    <label>Prénom*</label>
                                                    <input
                                                        type="text"
                                                        name="firstname"
                                                        onChange={(e) => setUser((previous) => ({ ...previous, "firstname": e.target.value }))}
                                                        onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))} />
                                                </div>
                                            </div>

                                            <div className="section-columns-layout" grid-template="1,1">
                                                <div>
                                                    <label>E-mail* <span className="error-text">{errorMailMessage}</span></label>
                                                    <input
                                                        type="text"
                                                        name="mail"
                                                        onChange={(e) => setUser((previous) => ({ ...previous, "mail": e.target.value }))}
                                                        onBlur={(e) => setErrorMailMessage(checkMailInput(e.target.value))} />
                                                </div>

                                                <div>
                                                    <label>Mot de passe*</label>
                                                    <input
                                                        type="password"
                                                        name="password"
                                                        onChange={(e) => setUser((previous) => ({ ...previous, "password": e.target.value }))}
                                                        onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))} />
                                                </div>
                                            </div>

                                            <div className="section-columns-layout" grid-template="1,1">
                                                <div>
                                                    <label>Téléphone*</label>
                                                    <PhoneInput
                                                        style={{ marginBottom: '30px', marginTop: '5px' }}
                                                        country={'fr'}
                                                        value={userData.phone}
                                                        onChange={(value) => setUser((previous) => ({ ...previous, "phone": value }))}
                                                        onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))} />
                                                </div>
                                                <div>
                                                    <label>Entreprise</label>
                                                    <input
                                                        type="text"
                                                        name="entreprise"
                                                        style={{ marginBottom: '8px' }}
                                                        onChange={(e) => setUser((previous) => ({ ...previous, "company": e.target.value }))} />
                                                </div>
                                            </div>

                                            <p className="error-text">{errorMessage}</p>
                                            <button disabled={disableButton(userData) && !isEmailAvailable} className="dark-bg">Créer un compte</button>
                                        </form>
                                        <span style={{ fontSize: '14px', display: 'flex' }}>Vous avez déjà un compte ?&nbsp;<Link to='/login'> Connectez-vous</Link></span>
                                    </>)}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}