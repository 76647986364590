import { Link } from "react-router-dom"
import Lottie from 'react-lottie';
import animationData from '../../../lotties/data_home_1.json';
import { useEffect, useState } from "react";

export default function HomeProducts(){
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const [counter, setCounter] = useState(1);

    const handleNext = () => {
        setCounter((counter + 1) % 3)
    };

    useEffect(() => {
        let interval = setInterval(() => handleNext(), 5000);
        return () => clearInterval(interval);
    });

    return (
        
        <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-columns-layout" grid-template="1,1" id="section-home-top">
                            <Lottie
                                options={defaultOptions} />
                            <section className="section-content-container">
                                <header>
                                    <h1 className="section-content-title">Les données au coeur de votre activité</h1>
                                    <h2 className="section-content-subtitle">Création, traitement et accessibilité</h2>
                                </header>
                                <p className="section-content-description">
                                    DIPS évolue dans le domaine de l'expertise fonctionnelle et dans la mise en place de gestion de base de données 
                                    depuis plus de 20 ans. Nous transformons le potentiel de vos données au travers de la réalisation de logiciels sur-mesure. 
                                    
                                </p>
                                <footer className="section-content-links-container">
                                    <Link to="/contact">Contactez l'équipe</Link>
                                    <Link to="/register">
                                        <button className="dark-bg">S'inscrire</button>
                                    </Link>
                                </footer>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
    )
}