import { Link } from "react-router-dom"

export default function HomeTopOverview() {

    return (
        <section className="section">
            <div className="section-container">
                <div className="section-container-layout" style={{padding : 150}}>
                    <section className="section-centered-layout">
                        <h1 className="section-home-title">
                            Créateurs de logiciels métiers
                        </h1>
                        <h3 className="section-home-subtitle">
                            Des idées et de l'innovation au service de vos Data
                        </h3>

                    </section>
                    <section className="section-centered-layout">
                        <div className="section-columns-layout" grid-template="1,1" id="section-home-top">
                            <Link to="/Products">
                                <button className="dark-bg">Visitez nos produits</button>
                            </Link>

                            <Link to="/library">
                                <button className="dark-bg">Notre solution Peasys</button>
                            </Link>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    )
}