import updateHead from "../../../utils/helper/updatePageHead";
import dayjs from 'dayjs'
import Loader from "react-js-loader";
import { useUser } from "../../../utils/contexts/userContext";
import { Link } from 'react-router-dom'
import { useEffect, useState } from "react";
import { getLicenseKeysFromUserId } from "../../../utils/api/userFetchs";

export default function AccountSubscriptions() {
    const title = "DIPS | Vos abonnements";

    const { user } = useUser();
    const [userLicenseKeys, setUserLicenseKeys] = useState(null)
    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    window.onresize = () => { setWindowWidth(window.innerWidth) }

    useEffect(() => {
        getLicenseKeysFromUserId(user).then(license_keys => setUserLicenseKeys(license_keys));
    }, [user._id])

    return (
        <>
            {updateHead({ title })}
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-content-container">
                            <header>
                                <h1 className="section-content-title">Vos abonnements</h1>
                                <h2 className="section-content-subtitle">Ici, gérer la vie de vos abonnements</h2>
                            </header>

                            <div className="section-info-container">
                                <div className="section-account-licensekey-list-header">
                                    <p>partition</p>
                                    <p>clé</p>
                                    {windowWidth > 600 &&
                                        <>
                                            <p>début</p>
                                            <p>fin</p>
                                        </>
                                    }
                                </div>
                                <div className="br-line" />
                                <ul className="section-account-licensekey-list">
                                    {!userLicenseKeys ?
                                        (<Loader type="rectangular-ping" color="#071F5A" bgColor="#071F5A" size={200} title="C'est bien vide ici..." />) :
                                        (<>
                                            {userLicenseKeys.map(key =>
                                            (<Link key={key._id} to={`/account/subscriptions/${key._id}`}>
                                                <li className="section-account-licensekey-item">
                                                    <span>{key.partition_name}</span>
                                                    <span>{key.key.substring(0, 12)}..</span>
                                                    {windowWidth > 600 &&
                                                        <>
                                                            <span>{dayjs(key.start_date).format("DD-MM-YYYY")}</span>
                                                            <span>{dayjs(key.end_date).format("DD-MM-YYYY")}</span>
                                                        </>
                                                    }
                                                </li>
                                            </Link>))
                                            }
                                        </>)
                                    }
                                </ul>
                            </div>

                            <div className="section-content-links-container">
                                <Link to='/pricing'>
                                    <button className="dark-bg">Souscrire à un nouvel abonnement</button>
                                </Link>
                                <Link to='/docs/install'>
                                    <button className="dark-bg">Lire le processus d'installation de Peasys</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}