import { useEffect, useState } from 'react';
export default function ServicesCarousel({ dataList, sens }) {

    const [animate, setAnimate] = useState(true)
    const [turn, setTurn] = useState(1)
    const [pageWidth, setPageWidth] = useState(window.innerWidth)
    window.addEventListener('resize', () => { setPageWidth(window.innerWidth) })
    
    useEffect(() => {
        var fields = document.getElementsByClassName('section-services-carousel-itemDot');

        var width = Math.max(20 * pageWidth / 100, 300);
        var height = Math.max(20 * pageWidth / 100, 300);

        var radius = width / 2;

        var step = (2 * Math.PI) / 3;
        var angle = 0

        Array.prototype.forEach.call(fields, function (element) {
            // Do stuff here
            var x = Math.round(width / 2 + radius * Math.cos(angle) - 60 / 2);
            var y = Math.round(height / 2 + radius * Math.sin(angle) - 60 / 2);
            angle += step
            element.style.left = `${x}px`
            element.style.top = `${y}px`
        });

    }, [pageWidth])

    useEffect(() => {
        const interval = setInterval(() => {
            var circles = document.getElementsByClassName('section-services-carousel-circle');
            Array.prototype.forEach.call(circles, function (element) {
                let rot = sens*turn* 120
                element.style.transform = `rotate(${rot}deg)`
                element.style.transition = `2s`
            });

            var items = document.getElementsByClassName('section-services-carousel-itemDot');
            Array.prototype.forEach.call(items, function (element) {
                element.style.transform = `rotate(${(0 - (turn) * 120)}deg)`
                element.style.transition = `700ms`
            });

            turn >= 3 ? setTurn(1) : setTurn(turn + 1)
            setAnimate(!animate)

        }, 10000);

        return () => clearInterval(interval);
    })

    return (
        <div style={{position:'relative'}}>
            <div className={"section-services-carousel-circle"}>
                {dataList.map((item, index) => (
                    <span className="section-services-carousel-itemDot" key={item.iconPath + index}>
                        <img src={item.iconPath} alt={item.iconPath} style={{ width: '40px' }} />
                    </span>
                ))}
            </div>
            <div className="section-services-carousel-content-container">
                {dataList.map((item, index) => (
                    <span className={"section-services-carousel-content-item " + (turn === index + 1 ? "active" : undefined)} key={item.iconPath + index}>
                        <h2>{item.title}</h2>
                        <p style={{ fontSize: '20' }} >{item.description}</p>
                    </span>
                ))}
            </div>
        </div>
    )
}