export const createQuery = {
    csharp: {
        data: `using Peasys;

namespace SqlCreate
{
    class Main
        {
        static void Main(string[] args)
        {
            PeaClient conn = new("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
            Console.WriteLine(conn.ConnectionMessage);
            
            PeaCreateResponse createResponse = conn.ExecuteCreate("CREATE TABLE schema_name/table_name (nom CHAR(10), age INT)");
            Console.WriteLine(createResponse.ReturnedSQLMessage);
            Console.WriteLine(createResponse.ReturnedSQLState);

            conn.Disconnect();
        }
    }
}`
    },
    python: {
        data: `from peasys import pea_client

conn = pea_client.PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false)
print(conn.connexion_message)

create_response = conn.execute_create("CREATE TABLE schema_table/table_name (nom CHAR(10), age INT)")
print(create_response.returnedSQLMessage)
print(create_response.returnedSQLState)

conn.disconnect()`
    },
    php: {
        data: `require(PeaClient.php)

$conn = new PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
echo $conn->connexionMessage;
$createResponse = $conn->executeCreate("CREATE TABLE schema_table/table_name (nom CHAR(10), age INT)");

echo $createResponse->returnedSQLMessage;
echo $createResponse->returnedSQLState;

$conn->disconnect();`
    },
    java: {
        data: `import Peasys.PeaClient;
import Peasys.PeaResponse.*;
        
public class Main {
    public static void main(String[] args) {
        try {
            PeaClient conn = new PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
            System.out.println(conn.connectionStatus);
            System.out.println(conn.connexionMessage);
                    
            PeaCreateResponse createResponse = conn.executeCreate("CREATE TABLE schema_table/table_name (nom CHAR(10), age INT)");
            System.out.println("Requete correctement exécutée : " + createResponse.hasSucceeded);
            System.out.println("Message SQL de la requete : " + createResponse.returnedSQLMessage);
            System.out.println("Status SQL de la requete : " + createResponse.returnedSQLState + "\n");
        
            conn.disconnect();
        } catch (Exception e) {
            System.out.println(e.getMessage());
        }
    }
}`
    },
    swift: {
        data: `import peasys_swift

let conn: PeaClient = try PeaClient(idClient: "ID_CLIENT", partitionName: "PARTITION_NAME", port: PORT, userName: "USERNAME", password: "PASSWORD", 
    retrieveStatitics: false, onlineVersion: true)
let res = try client.executeCreate("CREATE TABLE schema_table/table_name (nom CHAR(10), age INT)")
print(res.hasSucceeded)
print(res.return)
print(res.hasSucceeded)

conn.disconnect()
`},
    ruby: {
        data: `require "peasys"

conn = PeaClient.new("DNS_OR_IP", "PARTITON_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false)
    
createResponse = conn.execute_create("CREATE TABLE schema_table/table_name (nom CHAR(10), age INT)")
puts "Requete correctement exécutée : " + createResponse.has_succeeded
puts "Message SQL de la requete : " + createResponse.sql_message
puts "Status SQL de la requete : " + createResponse.sql_state
    
conn.disconnect` }
}

export const deleteQuery = {
    csharp: {
        data: `using Peasys;

namespace SqlDelete
{
    class Main
        {
        static void Main(string[] args)
        {
            PeaClient conn = new("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
            Console.WriteLine(conn.ConnectionMessage);
            
            PeaDeleteResponse deleteResponse = conn.ExecuteDelete("DELETE FROM schema_table/table_name WHERE condition")
            if(deleteResponse.HasSucceeded)
            {
                Console.WriteLine(deleteResponse.ReturnedSQLMessage);
                Console.WriteLine(deleteResponse.ReturnedSQLState);
            }

            conn.Disconnect();
        }
    }
}`
    },
    python: {
        data: `from peasys import pea_client

conn = pea_client.PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false)
print(conn.connexion_message)

delete_response = conn.execute_delete("DELETE FROM schema_table/table_name WHERE condition")
print(delete_response.sql_message)
print(delete_response.sql_state)

conn.disconnect()`
    },
    php: {
        data: `require(PeaClient.php)

$conn = new PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
echo $conn->connexionMessage;
$deleteResponse = $conn->executeDelete("DELETE FROM schema_table/table_name WHERE condition");

echo $deleteResponse->returnedSQLMessage;
echo $deleteResponse->returnedSQLState;

$conn->disconnect();`
    },
    java: {
        data: `import Peasys.PeaClient;
import Peasys.PeaResponse.*;
                
public class Main {
    public static void main(String[] args) {
        try {
            PeaClient conn = new PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
            System.out.println(conn.connectionStatus);
            System.out.println(conn.connexionMessage);
            
            PeaDeleteResponse deleteResponse = conn.executeInsert("DELETE FROM schema_table/table_name WHERE condition");
            System.out.println("Requete correctement exécutée : " + deleteResponse.hasSucceeded);
            System.out.println("Message SQL de la requete : " + deleteResponse.returnedSQLMessage);
            System.out.println("Status SQL de la requete : " + deleteResponse.returnedSQLState + "\n");
                
            conn.disconnect();
            } catch (Exception e) {
                System.out.println(e.getMessage());
            }
        }
    }
}`
    },
    swift: {
        data: `import peasys_swift

    let conn: PeaClient = try PeaClient(idClient: "ID_CLIENT", partitionName: "PARTITION_NAME", port: PORT, userName: "USERNAME", password: "PASSWORD", 
        retrieveStatitics: false, onlineVersion: true)
    let res = try client.executeDelete("DELETE FROM schema_table/table_name WHERE condition")
    print(res.hasSucceeded)
    print(res.return)
    print(res.hasSucceeded)

    conn.disconnect()
`},
    ruby: {
        data: `require "peasys"

conn = PeaClient.new("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false)

deleteResponse = conn.execute_delete("DELETE FROM schema_table/table_name WHERE condition")
puts "Requete correctement exécutée : " + deleteResponse.has_succeeded
puts "Message SQL de la requete : " + deleteResponse.sql_message
puts "Status SQL de la requete : " + deleteResponse.sql_state

conn.disconnect` }
}

export const insertQuery = {
    csharp: {
        data: `using Peasys;

namespace SqlInsert
{
    class Main
        {
        static void Main(string[] args)
        {
            PeaClient conn = new("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
            Console.WriteLine(conn.ConnectionMessage);
            
            PeaInsertResponse insertResponse = conn.ExecuteInsert("INSERT INTO schema_name/table_name VALUES ('user1', 47), ('user2', 32)");
            Console.WriteLine(insertResponse.ReturnedSQLMessage);
            Console.WriteLine(insertResponse.ReturnedSQLState);

            conn.Disconnect();
        }
    }
}`
    },
    python: {
        data: `from peasys import pea_client

conn = pea_client.PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false)
print(conn.connexion_message)

insert_response = conn.execute_insert("INSERT INTO schema_name/table_name VALUES ('user1', 47), ('user2', 32)")
print(insert_response.sql_message)
print(insert_response.sql_state)

conn.disconnect()`
    },
    php: {
        data: `require(PeaClient.php)

$conn = new PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
echo $conn->connexionMessage;
$insertResponse = $conn->executeInsert("INSERT INTO schema_name/table_name VALUES ('user1', 47), ('user2', 32)");

echo $insertResponse->returnedSQLMessage;
echo $insertResponse->returnedSQLState;

$conn->disconnect();`
    },
    java: {
        data: `import Peasys.PeaClient;
import Peasys.PeaResponse.*;
                        
public class Main {
    public static void main(String[] args) {
        try {
            PeaClient conn = new PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
            System.out.println(conn.connectionStatus);
            System.out.println(conn.connexionMessage);
                    
            PeaInsertResponse insertResponse = conn.executeInsert("INSERT INTO dips_nc/titi VALUES ('user1', 47), ('user2', 32)");
            System.out.println("Requete correctement exécutée : " + insertResponse.hasSucceeded);
            System.out.println("Message SQL de la requete : " + insertResponse.returnedSQLMessage);
            System.out.println("Status SQL de la requete : " + insertResponse.returnedSQLState + "\n");
                        
            conn.disconnect();
            } catch (Exception e) {
                System.out.println(e.getMessage());
            }
        }
    }
}`
    },
    swift: {
        data: `import peasys_swift

let conn: PeaClient = try PeaClient(idClient: "ID_CLIENT", partitionName: "PARTITION_NAME", port: PORT, userName: "USERNAME", password: "PASSWORD", 
    retrieveStatitics: false, onlineVersion: true)
let res = try client.executeInsert("INSERT INTO dips_nc/titi VALUES ('user1', 47), ('user2', 32)")
print(res.hasSucceeded)
print(res.return)
print(res.hasSucceeded)

conn.disconnect()
`},
    ruby: {
        data: `require "peasys"

conn = PeaClient.new("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false)

insertresponse = conn.execute_insert("INSERT INTO dips_nc/titi VALUES ('user1', 47), ('user2', 32)")
puts "Requete correctement exécutée : " + insertResponse.has_succeeded
puts "Message SQL de la requete : " + insertResponse.sql_message
puts "Status SQL de la requete : " + insertResponse.sql_state

conn.disconnect` }
}

export const selectQuery = {
    csharp: {
        data: `using Peasys;

namespace SqlSelect
{
    class Main
        {
        static void Main(string[] args)
        {
            PeaClient conn = new("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
            Console.WriteLine(conn.ConnectionMessage);
            
            PeaSelectResponse selectResponse = conn.ExecuteSelect("SELECT nom, age from schema_name/table_name");
            if(selectResponse.HasSucceeded)
            {
                Console.WriteLine(selectResponse.ReturnedSQLMessage);
                Console.WriteLine(selectResponse.ReturnedSQLState);

                Dictionary<string, List<dynamic>> dic = selectResponse.Result;
                for (int i = 0; i < selectResponse.RowCount; i++)
                {
                    Console.Write(dic["nom"][i]);
                    Console.Write(dic["age"][i]);
                    Console.WriteLine();
                }

            conn.Disconnect();
        }
    }
}`
    },
    python: {
        data: `from peasys import pea_client

conn = pea_client.PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false)
print(conn.connexion_message)

select_response = conn.execute_select("SELECT nom, age from schema_name/table_name")
if(select_response.has_succeeded):
    print(select_response.sql_message)
    print(select_response.sql_state)
    
    dic = select_response.result
    for i in range(0, select_response.row_count):
        print(dic["nom"][i])
        print(dic["age"][i])
        print()

conn.disconnect()`
    },
    php: {
        data: `require(PeaClient.php)

$conn = new PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
echo $conn->connexionMessage;
$selectResponse = $conn->executeSelect("SELECT nom, age from schema_name/table_name");

if($selectResponse->hasSucceeded)
{
    echo $selectResponse->returnedSQLMessage;
    echo $selectResponse->returnedSQLState;

    $res = $selectResponse->result;
    for($res as $row){
        echo "<p>" . print_r($row) . "</p>";
    }
}

$conn->disconnect();`
    },
    java: {
        data: `import Peasys.PeaClient;
import Peasys.PeaResponse.*;
                                
public class Main {
    public static void main(String[] args) {
        try {
            PeaClient conn = new PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
            System.out.println(conn.connectionStatus);
            System.out.println(conn.connexionMessage);
                            
            PeaInsertResponse insertResponse = conn.executeInsert("SELECT nom, age from schema_name/table_name");
            System.out.println("Requete correctement exécutée : " + insertResponse.hasSucceeded);
            System.out.println("Message SQL de la requete : " + insertResponse.returnedSQLMessage);
            System.out.println("Status SQL de la requete : " + insertResponse.returnedSQLState + "\n");
                                
            conn.disconnect();
            } catch (Exception e) {
                System.out.println(e.getMessage());
            }
        }
    }
}`
    },
    swift: {
        data: `import peasys_swift

let conn: PeaClient = try PeaClient(idClient: "ID_CLIENT", partitionName: "PARTITION_NAME", port: PORT, userName: "USERNAME", password: "PASSWORD", 
    retrieveStatitics: false, onlineVersion: true)
let res = try client.executeSelect("SELECT nom, age from schema_name/table_name")
    if res.hasSucceeded {
        for i in 0...res.row_count {
            print(res.result["nom"][i])
            print(res.result["age"][i])
        }
    }
conn.disconnect()
`},
    ruby: {
        data: `require "peasys"

conn = PeaClient.new("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false)

selectReponse = conn.execute_select("INSERT INTO dips_nc/titi VALUES ('user1', 47), ('user2', 32)")
if(select_response.has_succeeded) {
    puts select_response.sql_message
    puts select_response.sql_state
    
    selectResponse.row_count.each_index do |i|
      puts selectResponse.result["nom"][i]
      puts selectResponse.result["age"][i] + "\n"
    end
}

conn.disconnect` }
}

export const updateQuery = {
    csharp: {
        data: `using Peasys;

namespace SqlUpdate
{
    class Main
        {
        static void Main(string[] args)
        {
            PeaClient conn = new("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
            Console.WriteLine(conn.ConnectionMessage);
            
            PeaUpdateResponse updateResponse = conn.ExecuteUpdate("UPDATE schema_name/table_name SET date_naissance='1980-01-01'");
            Console.WriteLine(updateResponse.ReturnedSQLMessage);
            Console.WriteLine(updateResponse.ReturnedSQLState);

            conn.Disconnect();
        }
    }
}`
    },
    python: {
        data: `from peasys import pea_client

conn = pea_client.PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false)
print(conn.connexion_message)

update_response = conn.execute_update("UPDATE schema_name/table_name SET date_naissance='1980-01-01'")
print(update_response.sql_message)
print(update_response.sql_state)

conn.disconnect()`
    },
    php: {
        data: `require(PeaClient.php)

$conn = new PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
echo $conn->connexionMessage;

$updateResponse = $conn->executeUpdate("UPDATE schema_name/table_name SET date_naissance='1980-01-01'");
echo $updateResponse->returnedSQLMessage;
echo $updateResponse->returnedSQLState;

$conn->disconnect();`
    },
    java: {
        data: `import Peasys.PeaClient;
import Peasys.PeaResponse.*;
                                        
public class Main {
    public static void main(String[] args) {
        try {
            PeaClient conn = new PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
            System.out.println(conn.connectionStatus);
            System.out.println(conn.connexionMessage);
                                    
            PeaUpdateResponse updateResponse = conn.executeUpdate("UPDATE dips_nc/titi SET date_naissance='1980-01-01'");
            System.out.println("Requete correctement exécutée : " + updateResponse.hasSucceeded);
            System.out.println("Message SQL de la requete : " + updateResponse.returnedSQLMessage);
            System.out.println("Status SQL de la requete : " + updateResponse.returnedSQLState + "\n");
                                        
            conn.disconnect();
            } catch (Exception e) {
                System.out.println(e.getMessage());
            }
        }
    }
}`
    },
    swift: {
        data: `import peasys_swift

let conn: PeaClient = try PeaClient(idClient: "ID_CLIENT", partitionName: "PARTITION_NAME", port: PORT, userName: "USERNAME", password: "PASSWORD", 
    retrieveStatitics: false, onlineVersion: true)
let res = try client.executeUpdate("UPDATE dips_nc/titi SET date_naissance='1980-01-01'")
print(res.hasSucceeded)
print(res.return)
print(res.hasSucceeded)

conn.disconnect()
`},
    ruby: {
        data: `require "peasys"

conn = PeaClient.new("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false)

insertresponse = conn.execute_update("UPDATE schema_name/table_name SET date_naissance='1980-01-01'")
puts "Requete correctement exécutée : " + insertResponse.has_succeeded
puts "Message SQL de la requete : " + insertResponse.sql_message
puts "Status SQL de la requete : " + insertResponse.sql_state

conn.disconnect` }
}

export const alterQuery = {
    csharp: {
        data: `using Peasys;

namespace SqlAlter
{
    class Main
        {
        static void Main(string[] args)
        {
            PeaClient conn = new("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
            Console.WriteLine(conn.ConnectionMessage);
            
            PeaAlterResponse alterResponse = conn.ExecuteAlter("ALTER TABLE schema_name/table_name ADD COLUMN date_naissance DATE", false);
            Console.WriteLine(alterResponse.ReturnedSQLMessage);
            Console.WriteLine(alterResponse.ReturnedSQLState);

            conn.Disconnect();
        }
    }
}`
    },
    python: {
        data: `from peasys import pea_client

conn = pea_client.PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false)
print(conn.connexion_message)

alter_response = conn.execute_alter("ALTER TABLE schema_name/table_name ADD COLUMN date_naissance DATE", False)
print(alter_response.sql_message)
print(alter_response.sql_state)

conn.disconnect()`
    },
    php: {
        data: `require(PeaClient.php)

$conn = new PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
echo $conn->connexionMessage;

$alterResponse = $conn->executeAlter("ALTER TABLE schema_name/table_name ADD COLUMN date_naissance DATE", false);
echo $alterResponse->returnedSQLMessage;
echo $alterResponse->returnedSQLState;

$conn->disconnect();`
    },
    java: {
        data: `import Peasys.PeaClient;
import Peasys.PeaResponse.*;
                                                
public class Main {
    public static void main(String[] args) {
        try {
            PeaClient conn = new PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
            System.out.println(conn.connectionStatus);
            System.out.println(conn.connexionMessage);
                                            
            PeaAlterResponse alterResponse = conn.executeAlter("ALTER TABLE schema_name/table_name ADD COLUMN date_naissance DATE", false);
            System.out.println("Requete correctement exécutée : " + alterResponse.hasSucceeded);
            System.out.println("Message SQL de la requete : " + alterResponse.returnedSQLMessage);
            System.out.println("Status SQL de la requete : " + alterResponse.returnedSQLState + "\n");
                                                
            conn.disconnect();
            } catch (Exception e) {
                System.out.println(e.getMessage());
            }
        }
    }
}`
    },
    swift: {
        data: `import peasys_swift

let conn: PeaClient = try PeaClient(idClient: "ID_CLIENT", partitionName: "PARTITION_NAME", port: PORT, userName: "USERNAME", password: "PASSWORD", 
    retrieveStatitics: false, onlineVersion: true)
let res = try client.executeAlter("ALTER TABLE schema_name/table_name ADD COLUMN date_naissance DATE", false)
print(res.hasSucceeded)
print(res.return)
print(res.hasSucceeded)
    
conn.disconnect()
`},
    ruby: {
        data: `require "peasys"

conn = PeaClient.new("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false)

insertresponse = conn.execute_alter("ALTER TABLE schema_name/table_name ADD COLUMN date_naissance DATE", false)
puts "Requete correctement exécutée : " + insertResponse.has_succeeded
puts "Message SQL de la requete : " + insertResponse.sql_message
puts "Status SQL de la requete : " + insertResponse.sql_state

conn.disconnect` }
}

export const dropQuery = {
    csharp: {
        data: `using Peasys;

namespace SqlDrop
{
    class Main
        {
        static void Main(string[] args)
        {
            PeaClient conn = new("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
            Console.WriteLine(conn.ConnectionMessage);
            
            PeaDropResponse dropResponse = conn.ExecuteDrop("DROP TABLE schema_name/table_name")
            Console.WriteLine(dropResponse.ReturnedSQLMessage);
            Console.WriteLine(dropResponse.ReturnedSQLState);

            conn.Disconnect();
        }
    }
}`
    },
    python: {
        data: `from peasys import pea_client

conn = pea_client.PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false)
print(conn.connexion_message)

drop_response = conn.execute_drop("DROP TABLE schema_name/table_name")
print(drop_response.sql_message)
print(drop_response.sql_state)

conn.disconnect()`
    },
    php: {
        data: `require(PeaClient.php)

$conn = new PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
echo $conn->connexionMessage;

$dropResponse = $conn->executeDrop("DROP TABLE schema_name/table_name");
echo $dropResponse->returnedSQLMessage;
echo $dropResponse->returnedSQLState;

$conn->disconnect();`
    },
    java: {
        data: `import Peasys.PeaClient;
import Peasys.PeaResponse.*;
                                                        
public class Main {
    public static void main(String[] args) {
        try {
            PeaClient conn = new PeaClient("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false);
            System.out.println(conn.connectionStatus);
            System.out.println(conn.connexionMessage);
                                                    
            PeaDropResponse dropResponse = conn.executeDrop("DROP TABLE schema_name/table_name");
            System.out.println("Requete correctement exécutée : " + dropResponse.hasSucceeded);
            System.out.println("Message SQL de la requete : " + dropResponse.returnedSQLMessage);
            System.out.println("Status SQL de la requete : " + dropResponse.returnedSQLState + "\n");
                                                        
            conn.disconnect();
            } catch (Exception e) {
                System.out.println(e.getMessage());
            }
        }
    }
}`
    },
    swift: {
        data: `import peasys_swift

let conn: PeaClient = try PeaClient(idClient: "ID_CLIENT", partitionName: "PARTITION_NAME", port: PORT, userName: "USERNAME", password: "PASSWORD", 
    retrieveStatitics: false, onlineVersion: true)
let res = try client.executeDrop("DROP TABLE schema_name/table_name")
print(res.hasSucceeded)
print(res.return)
print(res.hasSucceeded)

conn.disconnect()
` },
    ruby: {
        data: `require "peasys"

conn = PeaClient.new("DNS_OR_IP", "PARTITION_NAME", PORT, "USERNAME", "PASSWORD", "ID_CLIENT", true, false)

insertresponse = conn.execute_drop("DROP TABLE schema_name/table_name")
puts "Requete correctement exécutée : " + insertResponse.has_succeeded
puts "Message SQL de la requete : " + insertResponse.sql_message
puts "Status SQL de la requete : " + insertResponse.sql_state

conn.disconnect` }
}