import { useState } from "react";
import { useUser } from "../../../utils/contexts/userContext"
import { checkEmptyInput, checkMailInput } from "../../../utils/helper/inputHelper";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import emailjs from '@emailjs/browser'


export default function ContactForm() {
    const { user } = useUser();

    const [errorMessage, setErrorMessage] = useState("")
    const [errorMailMessage, setErrorMailMessage] = useState("")
    const [isMailSent, setIsMailSent] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState({
        lastname: "",
        firstname: "",
        mail: "",
        entreprise: "",
        phone: "",
        title: "",
        message: "",
        reply_to: "",
    })

    const sendData = (e) => {
        e.preventDefault();

        if (!data.lastname || !data.firstname || !data.mail || !data.phone || !data.title || !data.message) {
            setErrorMessage("Les champs * doivent être renseignés")
            return
        }

        setIsLoading(true)
        emailjs.send("service_nnt9ye1", "template_wywkhbt", data, 'KbgKh-EcBL5hy_lPB')
            .then(data => {
                if (data.status === 200) {
                    setIsMailSent(true)
                }
                setIsLoading(false)
            });
    }

    if (isMailSent) {
        return (
            <div className="section-info-container">
                <h2>Merci !</h2>
                <p>
                    Nous avons bien pris en compte votre message. Un e-mail de confirmation va attérir dans votre boite mail.
                    Nous allons revenir vers vous au plus vite.
                </p>

                <img src="/assets/mail_sent.svg" alt="mail sent" className="icon" style={{ width: '60%', margin: '0 auto', display: 'flex' }} />

                <div className="section-columns-layout" grid-template="1,1">
                    <Link to='/docs'>
                        <button className="dark-bg" >Visiter la documentation</button>
                    </Link>
                    <Link to='/services'>
                        <button className="dark-bg" >Visiter nos services</button>
                    </Link>
                </div>
            </div>
        )
    }

    if (user._id) {
        data.lastname = user.lastname
        data.firstname = user.firstname
        data.mail = user.mail
        data.reply_to = user.mail
        data.entreprise = user.company
        data.phone = user.phone

        return (
            <div className="section-info-container">
                <h2>Vos Informations de contact</h2>
                <div>
                    <p style={{ margin: '8px 0' }}><strong>Nom : </strong>{user.lastname}</p>
                    <p style={{ margin: '8px 0' }}><strong>Prénom : </strong>{user.firstname}</p>
                    <p style={{ margin: '8px 0' }}><strong>Mail : </strong>{user.mail}</p>
                    <p style={{ margin: '8px 0' }}><strong>Tél : </strong>{user.phone}</p>
                </div>
                <form onSubmit={sendData}>
                    <label>Titre*</label>
                    <input
                        type="text"
                        name="entreprise"
                        style={{ marginBottom: '8px' }}
                        onChange={(e) => setData((previous) => ({ ...previous, "title": e.target.value }))}
                        onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))} />

                    <label>Message*</label>
                    <textarea
                        type="text"
                        rows={5}
                        name="entreprise"
                        style={{ marginBottom: '8px' }}
                        onChange={(e) => setData((previous) => ({ ...previous, "message": e.target.value }))}
                        onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))} />

                    <p className="error-text">{errorMessage}</p>
                    <button disabled={isLoading}
                        className={"dark-bg " + (isLoading ? "loading-button" : undefined)}>Envoyer</button>
                </form>
            </div>
        )
    } else {

        return (
            <div className="section-info-container">

                <h3>Veuillez saisir les informations ou <Link to='/login'><span className="highlight-title">connectez-vous</span></Link></h3>
                <form onSubmit={sendData} className="section-contact-form">
                    <div className="section-columns-layout" grid-template="1,1">
                        <div>
                            <label>Nom*</label>
                            <input
                                type="text"
                                name="lastname"
                                onChange={(e) => setData((previous) => ({ ...previous, "lastname": e.target.value }))}
                                onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))} />
                        </div>
                        <div>
                            <label>Prénom*</label>
                            <input
                                type="text"
                                name="firstname"
                                onChange={(e) => setData((previous) => ({ ...previous, "firstname": e.target.value }))}
                                onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))} />
                        </div>
                    </div>

                    <div className="section-columns-layout" grid-template="1,1">
                        <div>
                            <label>E-mail* <span className="error-text">{errorMailMessage}</span></label>
                            <input
                                type="text"
                                name="mail"
                                onChange={(e) => setData((previous) => ({ ...previous, "mail": e.target.value, "reply_to": e.target.value }))}
                                onBlur={(e) => setErrorMailMessage(checkMailInput(e.target.value))} />
                        </div>
                        <div>
                            <label>Téléphone*</label>
                            <PhoneInput
                                style={{ marginBottom: '5px', marginTop: '5px' }}
                                country={'fr'}
                                value={user.phone}
                                onChange={(value) => setData((previous) => ({ ...previous, "phone": value }))}
                                onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))} />
                        </div>
                    </div>

                    <div className="section-columns-layout" grid-template="1,1">
                        <div>
                            <label>Entreprise</label>
                            <input
                                type="text"
                                name="entreprise"
                                onChange={(e) => setData((previous) => ({ ...previous, "entreprise": e.target.value }))} />
                        </div>
                    </div>

                    <label>Titre*</label>
                    <input
                        type="text"
                        name="entreprise"
                        onChange={(e) => setData((previous) => ({ ...previous, "title": e.target.value }))}
                        onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))} />

                    <label>Message*</label>
                    <textarea
                        type="text"
                        rows={5}
                        name="entreprise"
                        style={{ marginBottom: '8px' }}
                        onChange={(e) => setData((previous) => ({ ...previous, "message": e.target.value }))}
                        onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))} />

                    <p className="error-text">{errorMessage}</p>
                    <button disabled={isLoading}
                        className={"dark-bg " + (isLoading ? "loading-button" : undefined)}>Envoyer</button>
                </form>
            </div>
        )
    }

}