import React, { useState } from "react";
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { createLicenseKey, updateLicenseKey, getLicenseKey, deleteLicenseKey } from "../../../utils/api/licensekeyFetchs";
import { useUser } from "../../../utils/contexts/userContext";
import { sha256 } from "js-sha256";
import { useNavigate } from "react-router-dom";

export default function CheckoutForm({ infos }) {
    const stripe = useStripe();
    const elements = useElements();
    const { user } = useUser();
    const navigate = useNavigate();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState(infos);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        var savedLicenseKey = null
        if (data._id) {
            data.is_available = false;
            savedLicenseKey = await updateLicenseKey(user, data)
        } else {
            savedLicenseKey = await createLicenseKey(data, user._id)
            data._id = savedLicenseKey._id
            setData(data)
        }


        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `http://localhost:3000/payement/${savedLicenseKey._id}`,
            },
        });

        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
            await deleteLicenseKey(data._id)
            sleep(5000).then(() => { window.location.reload() });
        } else {
            setMessage("An unexpected error occurred.");
        }
        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: "tabs"
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>

            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <button disabled={isLoading || !stripe || !elements} id="submit" className="section-payement-validation-button dark-bg">
                <span id="button-text">
                    {isLoading ? <div className="spinner" id="spinner"> Paiement en cours... </div> : "Payer"}
                </span>
            </button>
            {message && <div id="payment-message">{message}</div>}
        </form>
    );
}
function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }