import { useEffect, useState } from "react";
import { Link } from "react-router-dom"

export default function HomeServices() {
    const [counter, setCounter] = useState(1)

    useEffect(() => {
        let interval = setInterval(() => {
            setCounter((counter + 1) % 2)
        }, 5000);
        return () => clearInterval(interval);
    });

    return (
        <section className="section dark-bg">
            <div className="section-container">
                <div className="section-container-layout">
                    <div className="section-columns-layout" grid-template="1,1" id="section-home-top">
                        <div className="section-content-container">
                            <header>
                                <h1 className="section-content-title">
                                    <span className="highlight-title">Libérez</span> vos idées et <span className="highlight-title">concrétisez</span> vos
                                    objectifs avec notre expertise
                                </h1>
                                <h2 className="section-content-subtitle">Collaborons sur vos projets</h2>
                            </header>

                            <p className="section-content-description">
                                La société DIPS a construit son expertise au travers de la multitude de projets de ses clients. Bénéficiant d'une expérience métier
                                dans de nombreux secteurs (industrie, transport, BTP, logistique), elle a sut s'adapter à son environnement afin de proposer des services pour chaque instant du cycle 
                                de vie d'un projet.
                            </p>

                            <div className="section-content-links-container">
                                <Link to="/services">
                                    <button className="light-bg">En savoir plus</button>
                                </Link>
                            </div>
                        </div>
                        <div className="section-vertical-centered-layout">
                            <div className="section-home-services">
                                <div className="section-home-services-crop">
                                    <ul className="section-home-services-carousel-list" style={{ '--count': 4 }}>
                                        <li className="section-home-services-carousel-list-item" ><div className="section-home-services-card"><a href="/services">
                                            <span className="section-home-services-model-name">Analyse fonctionnelle</span>
                                            <span>Transformer les besoins en fonctionnalités et définir les outils pour les utiliser.</span></a></div>
                                        </li>
                                        <li className="section-home-services-carousel-list-item" ><div className="section-home-services-card"><a href="/services">
                                            <span className="section-home-services-model-name">Développement de logiciels métiers</span>
                                            <span>La combinaison gagnante entre notre maîtrise des systèmes informatiques et nos expériences.</span></a></div>
                                        </li>
                                        <li className="section-home-services-carousel-list-item" ><div className="section-home-services-card"><a href="/services">
                                            <span className="section-home-services-model-name">Expressions des besoins</span>
                                            <span>Souvent difficiles à les distinguer et à les exprimer. Elles sont le fondement du projet.</span></a></div>
                                        </li>
                                        <li className="section-home-services-carousel-list-item" ><div className="section-home-services-card"><a href="/services">
                                            <span className="section-home-services-model-name">Rédaction de cahier des charges</span>
                                            <span>Essentielle pour verbaliser et contractualiser nos relations.</span></a></div>
                                        </li>
                                        <li className="section-home-services-carousel-list-item" ><div className="section-home-services-card"><a href="/services">
                                            <span className="section-home-services-model-name">Maintenance applicative et suivi</span>
                                            <span>Identification, analyse et résolution des incidents quotidiens.</span></a></div>
                                        </li>
                                    </ul>
                                    <div className="section-home-services-last-circle" />
                                    <div className="section-home-services-second-circle" />
                                </div>
                                <div className="section-home-services-mask" />
                                <div className="section-home-services-center-circle" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}