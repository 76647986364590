import { useState } from 'react'
import { checkEmptyInput } from "../../../utils/helper/inputHelper";
import { sendOtp } from '../../../utils/api/userFetchs';
import { useUser } from "../../../utils/contexts/userContext";

export default function AskForRecovery({ changeContent, email, changeEmail }) {
    const [errorMessage, setErrorMessage] = useState('')
    const { user } = useUser()

    function sendRecoveryEmail(e) {
        e.preventDefault()

        if (!email && !user._id) {
            setErrorMessage("L'e-mail n'est pas valide.")
            return
        }

        sendOtp(user._id ? user.mail : email).then(({ code, message }) => {
            code === 200 ? changeContent('TokenInput') : setErrorMessage(message)
        }).catch(error => console.log(error))
    }

    return (
        <div className="section-info-container centered-info-container">
            <img src="/assets/logo_dips.svg" alt="company logo" className="icon logo-full"/>
            <p>{user._id ? "Cliquez sur le bouton ci-dessous afin de recevoir un code de modification de votre mot de passe sur votre mail : " + user.mail : "Renseignez votre email afin de recevoir un code de récupération de votre mot de passe."}</p>
            <div className="section-centered-layout">
                <form className="section-row-layout" onSubmit={sendRecoveryEmail}>
                    <div className= {user._id ? "display_none" : "display_block"}>
                        <label>E-mail*</label>
                        <input
                            type="text"
                            name="mail"
                            onChange={(e) => changeEmail(e.target.value)}
                            onBlur={(e) => setErrorMessage(checkEmptyInput(e.target.value))} />
                    </div>
                    <div>
                        <p className='error-text'>{errorMessage}</p>
                        <button className="dark-bg">{user._id ? "Envoyer un code de modification" : "Envoyer un code de récupération"}</button>
                    </div>
                </form>
            </div>
        </div>
    )
}