import updateHead from "../../utils/helper/updatePageHead";
import Footer from "../Footer/Footer";
import { Link } from "react-router-dom"

export default function Products() {
    const title = "DIPS | Nos produits";
    const cssPath = "/style/products.css";

    return (
        <>
            {updateHead({ title, cssPaths: [cssPath] })}
            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-content-container">
                            <header>
                                <h1 className="section-content-title">Profitez de notre <span className="highlight-title">bibliothèque de logiciels</span> développés avec Peasys</h1>
                                <h2 className="section-content-subtitle">Des logiciels basés sur notre expérience métier
                                    <br/><br/>Tous nos logiciels développent des fonctionnalités qui répondent à des besoins généraux. 
                                    Nous nous occupons de les tailler sur-mesure pour votre activité.<br/><br/>
                                </h2>
                                <Link to="/contact">
                                    <button className="dark-bg"><strong>Contactez-nous</strong></button>
                                </Link>
                            </header>
                        </div>
                        <h2 className="section-subtitle-variante"><strong>Quelques exemples : </strong></h2>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-content-container">
                            <section className="section-content-container">
                                <h2 className="section-subtitle-special">DeviDIPS  <span>deviser vos clients n'a jamais été aussi simple</span></h2>

                                <div className="section-columns-layout" grid-template="2,1">
                                    <p>
                                        DeviDIPS est un logiciel de création de devis complexe.
                                        Il permet la détermination exacte des besoins de l'utilisateur au travers d'un arbre de décision. 
                                        L'algorithme permet facilement la mise en place de règles de calculs en adequation avec votre activité.
                                    </p>
                                    <div >
                                        <img src="/assets/Deviseur-secteur.jfif" alt="Peasys Démo" style={{ height: '200px' }} />
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-content-container">
                            <section className="section-content-container">
                                <h2 className="section-subtitle-special">FlowDIPS  <span>vos flux d'équipement bien gérés</span></h2>
                                <div className="section-columns-layout" grid-template="2,1">
                                    <p>
                                    FlowDIPS est un logiciel de gestion de parc de matériel doté d'alertes pour le suivi d'éléments nécessitants des contrôles particuliers et/ou obligatoires.
                                    </p>
                                    <div style={{display:'flex', gap: '10px', alignItems: 'center'}}>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>

<           section className="section">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-content-container">
                            <section className="section-content-container">
                                <h2 className="section-subtitle-special">AccreDIPS  <span> gestion d'accréditations, d'habilitations et formations</span></h2>
                                <div className="section-columns-layout" grid-template="2,1">
                                    <p>
                                    Quand vos salariés sont tenus d'exercer leur activité au travers d'habilitations obligatoires , 
                                    AccreDips vous permet de gérer efficacement le suivi de la validité des accréditations et 
                                    de déclencher les visites et autres formations correspondantes auprès des organismes.
                                    </p>
                                    <div style={{display:'flex', gap: '10px', alignItems: 'center'}}/>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}