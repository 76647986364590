import React from "react";
import updateHead from "../../utils/helper/updatePageHead";
import { Link } from "react-router-dom";

export default function Footer() {
    const cssPath = "/style/footer.css";

    return (
        <>
            {updateHead({ cssPaths: [cssPath] })}
            <section className="section section-footer">
                <div className="section-container">
                    <div className="section-container-layout">
                        <div className="section-centered-layout">
                            <div className="section-columns-layout" grid-template="1,1,1,1">
                                <div>
                                    <img src="/assets/logo_dips_white.svg" className="icon" alt="company logo" style={{ width: 'calc(100% - 60px)' }} />
                                    {/*
                                        <div className="section-footer-lang-container">
                                            <img src="/assets/earth.svg" className="icon" alt="world" style={{ height: '1em', verticalAlign: 'middle' }} />
                                            <span>Français</span>
                                        </div>
                                    */}
                                </div>
                                <div>
                                    <Link to='/services'>
                                        <h1 className="section-footer-title">Prestations</h1>
                                    </Link>
                                    <ul className="section-footer-list">
                                        <li className="section-footer-list-item">
                                            <Link to='/services'>Expressions des besoins</Link>
                                        </li>
                                        <li className="section-footer-list-item">
                                            <Link to='/services'>Analyse fonctionnelle</Link>
                                        </li>
                                        <li className="section-footer-list-item">
                                            <Link to='/services'>Rédaction de cahier des charges</Link>
                                        </li>
                                        <li className="section-footer-list-item">
                                            <Link to='/services'>Développement de logiciels métiers</Link>
                                        </li>
                                        <li className="section-footer-list-item">
                                            <Link to='/services'>Veille technologique</Link>
                                        </li>
                                        <li className="section-footer-list-item">
                                            <Link to='/services'>Support/Assistance</Link>
                                        </li>
                                        <li className="section-footer-list-item">
                                            <Link to='/services'>Suivi de projets</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div>
                                    <h1 className="section-footer-title">Solutions</h1>
                                    <ul className="section-footer-list">
                                        <li className="section-footer-list-item">
                                            <Link to='/library'>Peasys</Link>
                                        </li>
                                    </ul>
                                    <Link to='/docs'>
                                        <h1 className="section-footer-title">Documentation</h1>
                                    </Link>
                                    <ul className="section-footer-list">
                                        <li className="section-footer-list-item">
                                            <Link to='/docs/install'>Installation</Link>
                                        </li>
                                        <li className="section-footer-list-item">
                                            <Link to='/docs/connexion'>Connexion</Link>
                                        </li>
                                        <li className="section-footer-list-item">
                                            <Link to='/docs/use-cases'>Utilisation</Link>
                                        </li>
                                    </ul>
                                    <Link to='/pricing'>
                                        <h1 className="section-footer-title">Tarifs</h1>
                                    </Link>
                                    <Link to='/company'>
                                        <h1 className="section-footer-title">Entreprise</h1>
                                    </Link>
                                </div>
                                <div>
                                    <Link to='/products'>
                                        <h1 className="section-footer-title">Produits</h1>
                                    </Link>
                                    <ul className="section-footer-list">
                                        <li className="section-footer-list-item">
                                            <Link to='/products'>Tous les produits</Link>
                                        </li>
                                    </ul>
                                    <Link to="/general-conditions">
                                        <h1 className="section-footer-title">Mentions légales</h1>
                                    </Link>
                                    <Link to="/contact">
                                        <h1 className="section-footer-title">Contact</h1>
                                    </Link>
                                    <Link to="https://www.linkedin.com/company/dips400/?viewAsMember=true">
                                        <img src="/assets/White_linkedin.svg" className="icon" alt="company logo" style={{ width: 'calc(35% - 50px)' }}/>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}