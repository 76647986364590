import updateHead from "../../utils/helper/updatePageHead";
import Footer from "../Footer/Footer"
import HomeServices from "./tmpl/HomeServices";
import HomePeasys from "./tmpl/HomePeasys";
import HomeProducts from "./tmpl/HomeProducts";
import HomeTopOverview from "./tmpl/HomeTopOverview";

export default function Home() {
    const title = "DIPS | L'informatique au service de votre métier";
    const cssPath = "/style/home.css";

    return (
        <>
            {updateHead({ title, cssPaths: [cssPath] })}
            <HomeTopOverview />
            <HomeServices />
            <HomeProducts />
            <HomePeasys />
            <Footer />
        </>
    )
}